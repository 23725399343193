<script lang="ts">
  const close = () => {
    dialog.close();
    open = false;
  };

  interface Props {
    open?: boolean;
    modal?: boolean;
    class?: string;
    children?: import('svelte').Snippet;
  }

  let {
    open = $bindable(false),
    modal = true,
    class: classes = "",
    children
  }: Props = $props();
  let dialog: undefined | HTMLDialogElement = $state();

  $effect(() => {
    if (dialog && open) {
      if (modal) dialog.showModal();
      else dialog.show();
    }
  });
</script>

<!-- svelte-ignore a11y_click_events_have_key_events -->
<!-- svelte-ignore a11y_no_noninteractive_element_interactions -->
<dialog
  bind:this={dialog}
  class="text-body w-[calc(100%-var(--space-edge-x)*2)] laptop:w-[calc(100%-var(--space-edge-x)*2+(var(--s-5)*2))] max-w-full my-[4.275rem] max-h-[calc(100dvh-4.275rem)] rounded-[1rem] {classes}"
  onclick={(event) => {
    const rect = dialog?.getBoundingClientRect();
    const isInDialog = rect &&
      rect.top <= event.clientY &&
      event.clientY <= rect.top + rect.height &&
      rect.left <= event.clientX &&
      event.clientX <= rect.left + rect.width;
    if (!isInDialog) close();
  }}
>
  <p class="absolute inset-[1em_1em_auto_auto] my-0">
    <button
      onclick={() => close()}
      class="button button-link relative right-[calc(-1*var(--p-button,0))] min-w-[3rem]"
    >
      <span class="sr-only">Lukk</span> <span class="close-icon"></span>
    </button>
  </p>

  {@render children?.()}
</dialog>
