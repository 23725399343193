<script lang="ts">
  import Button from "$lib/Button.svelte";
  import Dialog from "$lib/Dialog.svelte";
  import { callbackFunctionIsDisabled } from "./utils/featureToggleUtils";

  const callbackDisabled = callbackFunctionIsDisabled();

  interface Props {
    rotated?: boolean;
    class?: string;
    children?: import("svelte").Snippet;
  }

  let { rotated = false, class: classes = "", children }: Props = $props();
  let dialogOpen = $state(false);
</script>

{#if !callbackDisabled}
  <div class={classes} class:rotated>
    <p>
      <Button
        primary
        onclick={() => {
          dialogOpen = true;
        }}
        class="hyphens-manual"
      >
        {#if children}{@render children()}{:else}Ring meg!{/if}
      </Button>
    </p>
  </div>

  <Dialog bind:open={dialogOpen} class="py-8">
    {#if dialogOpen}
      {#await import("./CallMeModalContent.svelte") then Module}
        <Module.default />
      {/await}
    {/if}
  </Dialog>
{/if}

<style lang="postcss">
  div:not(.rotated) p {
    margin-block: var(--mx, var(--space));
  }

  .rotated {
    display: none;
  }

  @media (--laptop) {
    .rotated {
      position: fixed;
      inset: 0 -1em 0 auto;
      width: 5em;
      overflow: hidden;
      height: 100%;
      display: flex;
      align-items: center;
      justify-items: flex-start;

      p {
        margin: 0;
        rotate: -90deg;
        width: 100%;
      }

      :global(.button) {
        white-space: nowrap;
        padding-block: 1em 2em;
        border-radius: 1rem;
      }
    }
  }
</style>
